import React from 'react'
import Error404 from '../components/errorPage/Error404'

function Error404Page() {
  return (
    <div>
        <Error404/>
    </div>
  )
}

export default Error404Page