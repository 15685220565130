import React from "react";
import { Link } from "react-router-dom";
// import {reviewIcon } from "../Common/SvgIcon";
function GridReview({ review, preview }) {

  const reviewIcon = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 10.5L22.9498 15.3602L28.1875 16.1398L24.3438 19.6875L25.625 24.9375L20.5 21.9844L15.375 24.9375L16.6562 19.6875L12.8125 16.1398L18.1937 15.3602L20.5 10.5Z"
        fill="black"
      />
      <path
        d="M22.7243 39.375L20.5 38.0625L25.625 28.875H33.3125C33.6492 28.8755 33.9826 28.808 34.2937 28.6762C34.6049 28.5445 34.8875 28.3512 35.1256 28.1073C35.3636 27.8635 35.5524 27.5739 35.681 27.2552C35.8096 26.9364 35.8755 26.5949 35.875 26.25V10.5C35.8755 10.1551 35.8096 9.81356 35.681 9.49484C35.5524 9.17613 35.3636 8.88655 35.1256 8.64269C34.8875 8.39883 34.6049 8.2055 34.2937 8.07376C33.9826 7.94203 33.6492 7.87448 33.3125 7.875H7.6875C7.35085 7.87448 7.0174 7.94203 6.70628 8.07376C6.39515 8.2055 6.11246 8.39883 5.87441 8.64269C5.63636 8.88655 5.44763 9.17613 5.31903 9.49484C5.19043 9.81356 5.12449 10.1551 5.125 10.5V26.25C5.12449 26.5949 5.19043 26.9364 5.31903 27.2552C5.44763 27.5739 5.63636 27.8635 5.87441 28.1073C6.11246 28.3512 6.39515 28.5445 6.70628 28.6762C7.0174 28.808 7.35085 28.8755 7.6875 28.875H19.2188V31.5H7.6875C6.32827 31.5 5.0247 30.9469 4.06358 29.9623C3.10245 28.9777 2.5625 27.6424 2.5625 26.25V10.5C2.56233 9.81051 2.69478 9.12775 2.95227 8.49071C3.20977 7.85367 3.58727 7.27485 4.0632 6.7873C4.53914 6.29976 5.10418 5.91306 5.72605 5.64928C6.34792 5.38551 7.01443 5.24983 7.6875 5.25H33.3125C33.9856 5.24983 34.6521 5.38551 35.274 5.64928C35.8958 5.91306 36.4609 6.29976 36.9368 6.7873C37.4127 7.27485 37.7902 7.85367 38.0477 8.49071C38.3052 9.12775 38.4377 9.81051 38.4375 10.5V26.25C38.4375 27.6424 37.8975 28.9777 36.9364 29.9623C35.9753 30.9469 34.6717 31.5 33.3125 31.5H27.1177L22.7243 39.375Z"
        fill="black"
      />
    </svg>
  );
  return (
    <div className="grid grid-cols-2 items-center">
      {review.map((reviewDetails, index) => {
        return reviewDetails?.reviewName ? (
          <Link
            to={`${preview ? reviewDetails?.reviewUrl : ""}`}
            target={`${preview ? "_blank" : ""}`}
          >
            <div className="max-w-[197px] h-[139px] border-[#F2F2F2] border items-center p-8">
              <div className="flex items-center justify-center h-[54px]">
                {reviewIcon}
              </div>

              <p className="text-[11px] text-black font-medium text-center">
                {reviewDetails.reviewName}
              </p>
            </div>
          </Link>
        ) : (
          ""
        );
      })}
    </div>
  );
}

export default GridReview;
